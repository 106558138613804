<template lang="html">
  <ListCard :selected="active">
    <template #title>
      <span>{{ $i18n.format(image.recordingDate, 'date') }} </span>
      <small>{{ readableDataSource }}</small>
    </template>
    <template #header>
      <span v-if="goodEnough" class="thumbs-up">👍</span>
      <div class="bars" :title="statistics">
        <div class="ndvi" :style="{width: `${Math.max(0, image.ndviMean * 100)}%`}" />
        <div class="cloud" :style="{width: `${image.cloudMean * 100}%`}" />
      </div>
    </template>
    <template #addon>
      <div class="checkbox-container" @click.stop="$emit('toggle')">
        <i class="fa fa-lg" :class="[icon]" />
      </div>
    </template>
  </ListCard>
</template>

<script>
import ListCard from '@components/card/ListCard'

export default {
  components: {
    ListCard
  },
  props: {
    selected: Boolean,
    active: Boolean,
    image: Object
  },
  computed: {
    icon () {
      return this.selected ? 'fa-check-square-o' : 'fa-square-o'
    },
    statistics () {
      if (this.image.dataSource === 'Sentinel2AtmosphericallyCorrected') {
        return `NDVI ${this.$i18n.format(this.image.ndviMean, 'f2')}\n${this.$i18n.format(this.image.cloudMean, 'percent')} ⛅`
      } else {
        const correctedNdvi = this.$i18n.format(this.image.ndviMean + 0.085, 'f2')
        return `NDVI ${this.$i18n.format(this.image.ndviMean, 'f2')} (${this.$i18n.translate('frs.zoneMaps.image.correctedNdvi')} ${correctedNdvi})\n${this.$i18n.format(this.image.cloudMean, 'percent')} ⛅`
      }
    },
    readableDataSource () {
      switch (this.image.dataSource) {
      case 'Sentinel2': return 'S2-L1C'
      case 'Sentinel2AtmosphericallyCorrected': return 'S2-L2A'
      default: return this.image.dataSource
      }
    },
    goodEnough () {
      return this.image.validForAuto
    }
  }
}
</script>

<style lang="scss" scoped>
.fa {
  cursor: pointer;
  transition: color 0.25s;

  &.fa-square-o {
    margin-right: 2px;
  }

  &:hover {
    color: darkgray;
  }
}

small {
  color: gray;
}

.checkbox-container {
  flex: 1;
  display: flex;
  align-items: center;
}

.thumbs-up {
  margin-left: auto;
  align-self: center;
  margin-right: 0.5em;
}

.bars {
  align-self: center;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  width: 50px;
  height: 40%;
  padding: 1px;
  background-color: #eaeaea;
  cursor: help;

  &:first-child {
    margin-left: auto;
  }

  .ndvi {
    flex: 2;
    background-color: #71a72b;
    margin-bottom: 1px;
  }

  .cloud {
    flex: 1;
    background-color: #a9a9a9;
  }
}

// .channel-buttons {
//   display: flex;
//   flex-direction: column;
//   flex: 1;
//
//   > button {
//     flex: 1 1 0;
//     min-height: 0;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//
//     font-size: 0.75em;
//
//     border: 1px solid gray;
//
//     &:not(:first-child) {
//       border-top: none;
//     }
//   }
// }
</style>
