<template lang="html">
  <IxVectorLayer
    :features="features"
    :vector-style="vectorStyle"
    :z-index="1"
  />
</template>

<script>
import axios from 'axios'
import {mapGetters} from 'vuex'

// TODO use different style for just field outline
import {fieldBorderSelected} from '@frs/map-styles'

import IxVectorLayer from '@components/map/IxVectorLayer'

export default {
  components: {
    IxVectorLayer
  },
  data () {
    return {
      loading: false,
      feature: null
    }
  },
  computed: {
    ...mapGetters('fieldRecordSystem', [
      'fieldId',
      'harvestYear'
    ]),
    features () {
      return this.feature ? [this.feature] : []
    },
    vectorStyle () {
      return fieldBorderSelected
    }
  },
  methods: {
    async reloadGeometry () {
      if (this.loading || !this.fieldId || !this.harvestYear) return

      this.feature = null
      this.loading = true

      const url = `/api/v2/entities/fields/${this.fieldId}/geodata/${this.harvestYear}`
      const response = await axios.get(url)

      this.feature = {
        type: 'Feature',
        properties: {},
        geometry: response.data
      }
      this.loading = false

      await this.$store.dispatch('fieldRecordSystem/map/focus', this.feature)
    }
  },
  watch: {
    fieldId: {
      immediate: true,
      handler: 'reloadGeometry'
    },
    harvestYear: {
      immediate: true,
      handler: 'reloadGeometry'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
