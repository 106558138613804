<template>
  <div v-if="debug" class="wfs-map-layer">
    <p><strong>URL: </strong>{{ url }}</p>
  </div>
</template>
<script>

import {makeWMSLayer} from '@helpers/openlayers/layers'
import {geoJsonFormat} from '@helpers/openlayers/features'
import {extend, createEmpty} from 'ol/extent'
import {buffer} from '@turf/turf'

export default {
  inject: [
    'getMap'
  ],
  props: {
    url: String,
    layers: {},
    debug: Boolean,
    clipFeatures: Array
  },
  methods: {
    recreate () {
      const {url, layers} = this

      if (this.layer) {
        this.map.getLayers().remove(this.layer)
        this.layer = null
      }

      this.layer = makeWMSLayer({url, layers})

      if (this.clipFeatures) {
        const olFeatures = this.clipFeatures.map(feature => geoJsonFormat.readFeature(buffer(feature, 500, 'meters')))

        const extent = olFeatures.reduce((combined, x) => extend(combined, x.getGeometry().getExtent()), createEmpty())

        this.layer.setExtent(extent)
      }

      this.map.getLayers().push(this.layer)
    }
  },
  watch: {
    url (url) {
      this.layer.getSource().setUrl(url)
    },
    layers (layers) {
      this.layer.getSource().updateParams({
        LAYERS: layers
      })
    }
  },
  updated () {
    this.recreate()
  },
  created () {
    this.getMap().then(map => {
      this.map = map

      this.recreate()
    })
  },
  beforeDestroy () {
    if (this.layer) {
      this.map.getLayers().remove(this.layer)
    }
  },
  render () {
    return null
  }
}
</script>

<style lang="scss" scoped>
.wfs-map-layer {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  border: 1px solid red;
  background-color: lighten(red, 40%);
}
</style>
