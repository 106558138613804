<template lang="html">
  <div class="satellite-image-selection">
    <div class="settings">
      <ChannelSelector v-if="shownImage" v-model="shownChannel" :image="shownImage" />

      <FilterSelector v-model="filter" />

      <Search
        v-model="searchText" :data="sortedImages"
        :keys="searchKeys"
        :threshold="0.15" @result="searchResult = $event"
      />
    </div>

    <SatImageLayer v-if="shownImageId" :image="shownImage" :channel="shownChannel" />

    <FrsCurrentFieldBorderLayer />

    <div class="image-list">
      <HelpBox v-if="searchResult || filter">
        <template v-if="filter === 'cultivations'">
          <IxRes>frs.zoneMaps.hints.filterImagesByCultivations</IxRes>
        </template>
        <template v-else-if="filter === 'selected'">
          <IxRes>frs.zoneMaps.hints.showingOnlySelected</IxRes>
        </template>
        <template v-if="searchResult">
          <IxRes v-if="searchResult.length" :context="{0: searchResult.length}">frs.zoneMaps.hints.searchResultCount</IxRes>
          <IxRes v-else>frs.zoneMaps.hints.noSearchResult</IxRes>
        </template>

        <IxRes v-if="notVisibleCount" :context="{0: notVisibleCount}">frs.zoneMaps.hints.moreSelectedButNotVisible</IxRes>
      </HelpBox>

      <template v-for="item in (searchResult || sortedItems)">
        <SatImageCard
          v-if="item.id"
          :key="item.id"
          :image="item"
          :selected="selectedImageIds.includes(item.id)"
          :active="shownImageId === item.id"
          @toggle="toggleImage(item.id)"
          @click.native="onClick(item.id)"
        />
        <LoadMoreButton
          v-else-if="filter !== 'selected'"
          :key="`${item.from}-${item.to}`"
          :date-range="item"
          :scheduled-date-ranges="scheduledDateRanges"
          @click="loadSatelliteImages([item])"
        />
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {orderBy} from 'lodash'
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import Search from '@components/search/Search'
import HelpBox from '@components/help/HelpBox'
import FrsCurrentFieldBorderLayer from '@frs/components/base/FrsCurrentFieldBorderLayer'

import ChannelSelector from './ChannelSelector'
import FilterSelector from './FilterSelector'
import SatImageLayer from './SatImageLayer'
import SatImageCard from './SatImageCard'
import LoadMoreButton from './LoadMoreButton'

export default {
  components: {
    Search,
    HelpBox,
    ChannelSelector,
    FilterSelector,
    SatImageLayer,
    FrsCurrentFieldBorderLayer,
    SatImageCard,
    LoadMoreButton
  },
  data () {
    return {
      selectedImage: null,
      searchText: '',
      searchKeys: ['dataSource', 'recordingDate'],
      searchResult: null
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/zoneMaps', [
      'satelliteImages',
      'scheduledDateRanges'
    ]),
    ...mapState('fieldRecordSystem/zoneMaps', {
      selectedImageIds: state => state.settings.selectedImageIds
    }),
    ...mapFormFields('fieldRecordSystem/zoneMaps', [
      'ui.shownChannel',
      'ui.filter',
      'settings.shownImageId'
    ]),
    ...mapGetters('fieldRecordSystem/zoneMaps', [
      'yearsWithCultivations',
      'nextDateRanges'
    ]),
    notVisibleCount () {
      const images = this.searchResult || this.sortedImages

      return this.selectedImageIds.filter(id => !images.find(x => x.id === id)).length
    },
    shownImage () {
      return this.satelliteImages.find(x => x.id === this.shownImageId)
    },
    filteredImages () {
      switch (this.filter) {
      case 'cultivations': return this.satelliteImages
        .filter(image => this.yearsWithCultivations.includes(moment(image.recordingDate).year()))
      case 'selected': return this.satelliteImages
        .filter(image => this.selectedImageIds.includes(image.id))
      default: return this.satelliteImages
      }
    },
    sortedImages () {
      return orderBy(this.filteredImages, ['recordingDate'], ['desc'])
    },
    sortedItems () {
      const items = this.filteredImages.concat(this.nextDateRanges)

      return orderBy(items, x => x.recordingDate || x.from, ['desc'])
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/zoneMaps', [
      'toggleImage',
      'setShownChannel'
    ]),
    ...mapActions('fieldRecordSystem/zoneMaps', [
      'loadSatelliteImages'
    ]),
    onClick (imageId) {
      if (this.shownImageId === imageId) {
        this.shownImageId = null
      } else {
        this.shownImageId = imageId
      }
    }
  },
  watch: {
    shownImage (image) {
      if (!image) return
      if (image.dataSource === 'Landsat' && this.shownChannel === 'clm') {
        this.setShownChannel('ndvi')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.satellite-image-selection {
  flex: 1;
  display: flex;
  flex-direction: column;

  min-height: 0;

  .image-list {
    flex: 1;

    display: flex;
    flex-direction: column;

    padding: 0.25em;
    overflow-y: auto;
  }

  .settings {
    display: flex;
    flex-direction: column;
    padding: 0.5em;

    > .filter-selector {
      margin-bottom: 0.5em;
    }
  }
}
</style>
