<template lang="html">
  <div class="filter-selector">
    <label>
      <IxRes>frs.zoneMaps.labels.filterSelector</IxRes>
    </label>

    <div class="btn-group btn-group-justified">
      <div
        v-for="filter in availableFilters" :key="filter"
        class="btn-group"
      >
        <ToggleButton v-model="modelProxy" :value="filter" required>
          <IxRes>frs.zoneMaps.buttons.filter_{{ filter || 'none' }}</IxRes>
        </ToggleButton>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleButton from '@components/ToggleButton'

import {modelProxy} from '@helpers/vuex'

export default {
  components: {
    ToggleButton
  },
  props: {
    value: String
  },
  computed: {
    modelProxy,
    availableFilters () {
      return [null, 'cultivations', 'selected']
    }
  }
}
</script>

<style lang="scss" scoped>
.channel-selector {
  display: flex;
  flex-direction: column;
}
</style>
