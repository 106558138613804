<template lang="html">
  <div class="channel-selector">
    <label>
      <IxRes>frs.zoneMaps.labels.channelSelector</IxRes>
    </label>

    <div class="btn-group btn-group-justified">
      <div
        v-for="channel in availableChannels" :key="channel"
        class="btn-group"
      >
        <ToggleButton
          v-model="modelProxy"
          :value="channel"
          :disabled="image.dataSource === 'Landsat' && channel === 'clm'"
          :title="toggleButtonTitle(channel)"
          required
        >
          {{ channel.toUpperCase() }}
        </ToggleButton>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleButton from '@components/ToggleButton'

import {modelProxy} from '@helpers/vuex'

export default {
  components: {
    ToggleButton
  },
  props: {
    value: String,
    image: Object
  },
  computed: {
    modelProxy,
    availableChannels () {
      return ['ndvi', 'cir', 'cloud', 'clm']
    }
  },
  methods: {
    toggleButtonTitle (channel) {
      if (this.image.dataSource === 'Landsat' && channel === 'clm') {
        return this.$i18n.translate('frs.zoneMaps.channelSelector.dataSource.title.disabledClm')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.channel-selector {
  display: flex;
  flex-direction: column;
}
</style>
