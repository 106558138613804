<template lang="html">
  <IxWmsLayer :url="url" :layers="layer" />
</template>

<script>
import {config} from 'src/js/infrastructure'

import IxWmsLayer from '@components/map/IxWmsLayer'

export default {
  components: {
    IxWmsLayer
  },
  props: {
    image: {
      type: Object,
      required: true
    },
    channel: {
      type: String,
      default: 'ndvi',
      validator: value => /ndvi|cir|cloud|clm/.test(value)
    }
  },
  computed: {
    baseUrl () {
      return config.sentinelHubPreviewInstances[this.image.dataSource]
    },
    url () {
      const {recordingDate} = this.image

      return `${this.baseUrl}?TIME=${recordingDate}/${recordingDate}`
    },
    layerPrefix () {
      switch (this.image.dataSource) {
      case 'Sentinel2': return 'S2_L1C_'
      case 'Sentinel2AtmosphericallyCorrected': return 'S2_L2A_'
      case 'Landsat': return 'L8_'
      default: return ''
      }
    },
    layerSuffix () {
      switch (this.channel) {
      case 'cir': return 'CIR'
      case 'ndvi': return 'NDVI'
      case 'cloud': return 'CLOUD'
      case 'clm': return 'CLMCLP'
      default: throw new Error('invalid channel')
      }
    },
    layer () {
      return this.layerPrefix + this.layerSuffix
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
