<template lang="html">
  <div class="zone-map-creation">
    <h3>
      <IxRes>frs.zoneMaps.header.zoneMapCreation</IxRes>
    </h3>

    <HelpBox>
      <IxRes>frs.zoneMaps.hints.introduction</IxRes>
    </HelpBox>

    <RedBorderPreview
      v-model="buffer"
      :enabled.sync="useRedBorder"
      :original-border="borderGeometry"
      @error="$emit('error', $event)"
    />

    <VueSelectize
      v-model="algorithm" :options="algorithmOptions"
      name="algorithm" required
      :fields="algorithmFields"
    >
      <template #label>
        <IxRes>frs.zoneMaps.label.algorithm</IxRes>
      </template>
    </VueSelectize>

    <div class="algorithm-parameters">
      <h4>
        <IxRes>frs.zoneMaps.headers.algorithmParameters</IxRes>
      </h4>

      <NumericInput v-model="minimumCorrelation" name="minimumCorrelation" required>
        <template #label>
          <IxRes>frs.zoneMaps.label.minimumCorrelation</IxRes>
        </template>
      </NumericInput>

      <div v-if="algorithm !== 'Old'">
        <VueSelectize
          v-model="zoneCount" :options="zoneCountOptions"
          name="zoneCount" required
          allow-empty placeholder="Auto"
        >
          <template #label>
            <IxRes>frs.zoneMaps.label.zoneCount</IxRes>
          </template>
        </VueSelectize>
      </div>

      <div v-if="algorithm === 'Old'">
        <NumericInput v-model="classLimit" name="classLimit" required>
          <template #label>
            <IxRes>frs.zoneMaps.label.classLimit</IxRes>
          </template>
        </NumericInput>
        <NumericInput v-model="outerClassLimit" name="outerClassLimit" required>
          <template #label>
            <IxRes>frs.zoneMaps.label.outerClassLimit</IxRes>
          </template>
        </NumericInput>
      </div>
    </div>

    <HelpBox v-if="fieldStates.selectedImageIds === 'error'" type="danger">
      <IxRes>frs.zoneMaps.hints.noImagesSelected</IxRes>
    </HelpBox>

    <TextInput
      v-model="orderId" name="orderId"
      :rule="validateOrderIdRule" required
    >
      <template #label>
        <IxRes>frs.zoneMaps.label.orderId</IxRes>
      </template>
    </TextInput>

    <HelpBox v-if="orderId === null || orderId === ''" type="warning">
      <IxRes>frs.zoneMaps.hints.noOrderIdConsequencesWarning</IxRes>
    </HelpBox>

    <HelpBox v-if="mapCountForOrder > 0">
      <IxRes>frs.zoneMaps.hints.orderAlreadyHasMapsWarning</IxRes>
    </HelpBox>

    <HelpBox v-if="mapCountForOrder === -1" type="danger">
      <IxRes>frs.zoneMaps.hints.orderIdNotFoundWarning</IxRes>
    </HelpBox>

    <div class="buttons">
      <FrsLoadingIndicator :loading="creating || isLoadingMapCount" small />
      <IxButton
        class="quit-button"
        large cancel
        @click="quit"
      />
      <IxButton large save @click="onCreate()">
        <IxRes>frs.zoneMaps.buttons.create</IxRes>
      </IxButton>
      <IxButton large save @click="onCreate(true)">
        <IxRes>frs.zoneMaps.buttons.createAndQuit</IxRes>
      </IxButton>
    </div>

    <div class="cultivationTableContainer">
      <FrsLoadingIndicator :requests="['frs.zoneMaps.getAllCultivationDataForField']" />
      <FrsCultivationOverviewTable :cultivations="cultivations" />
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import {notifications} from 'src/js/infrastructure'
import {updateNotifications} from 'src/coffee/infrastructure/ixmap/notifications-panel'

import FrsCultivationOverviewTable from '@frs/components/base/FrsCultivationOverviewTable'

import NumericInput from '@components/forms/NumericInput'
import TextInput from '@components/forms/TextInput'
import VueSelectize from '@components/VueSelectize'
import IxButton from '@components/IxButton'
import HelpBox from '@components/help/HelpBox'
import RedBorderPreview from '@components/geometry/RedBorderPreview'
import FormPartMixin from '@components/forms/FormPartMixin'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import DisableDefaultMapInteractionsMixin from '@frs/mixins/DisableDefaultMapInteractionsMixin'

export default {
  components: {
    FrsCultivationOverviewTable,
    NumericInput,
    TextInput,
    VueSelectize,
    HelpBox,
    IxButton,
    FrsLoadingIndicator,
    RedBorderPreview
  },
  mixins: [
    FormPartMixin,
    DisableDefaultMapInteractionsMixin
  ],
  data () {
    return {
      creating: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/zoneMaps', [
      'cultivations',
      'borderGeometry'
    ]),
    ...mapState('fieldRecordSystem/zoneMaps', {
      selectedImageIds: state => state.settings.selectedImageIds,
      mapCountForOrder: state => state.mapCountForOrder
    }),
    ...mapFormFields('fieldRecordSystem/zoneMaps', [
      'settings.minimumCorrelation',
      'settings.zoneCount',
      'settings.classLimit',
      'settings.outerClassLimit',
      'settings.algorithm',
      'settings.orderId',
      'settings.useRedBorder',
      'settings.buffer'
    ]),
    algorithmOptions () {
      // these correspond exactly to the C# AlgorithmTypeEnum values
      return [
        'Old',
        'AbsoluteTreatment',
        'RelativeTreatment',
        'OldExtended'
      ]
    },
    algorithmFields () {
      return {
        text: value => this.$i18n.translate('frs.zoneMaps.algorithm.option.' + value)
      }
    },
    zoneCountOptions () {
      return [1, 3, 5, 7, 9, 11, 13, 15]
    },
    hasValidOrderId () {
      return !!this.orderId && !this.validateOrderIdRule(this.orderId)
    },
    isLoadingMapCount () {
      return this.hasValidOrderId && this.mapCountForOrder === null
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/zoneMaps', [
      'save',
      'createZoneMap',
      'getMapCountForOrder',
      'resetMapCount'
    ]),
    async quit () {
      await this.save()

      this.$router.push({name: 'fieldDashboard', params: this.$route.params})
    },
    async onCreate (quit) {
      this.validate()
      this.validateSelectedImageIds()

      await this.$nextTick()

      if (this.state !== 'success') {
        return
      }

      try {
        this.creating = true

        await this.createZoneMap()
        notifications.success(
          this.$i18n.translate('frs.zoneMaps.creation.messages.creationSuccessProgressInMessageWindowHint'),
          this.$i18n.translate('frs.zoneMaps.creation.messages.creationSuccessfullyTriggered')
        )

        if (quit) {
          await this.quit()
        }
      } catch (error) {
        if (error.response && error.response.data === 'OrderIdInvalid') {
          notifications.error(this.$i18n.translate('frs.zoneMaps.creation.messages.orderIdInvalid'))
        } else {
          notifications.error(this.$i18n.translate('frs.zoneMaps.creation.messages.creationTriggerFailed'))
        }
      } finally {
        this.creating = false
        updateNotifications()
      }
    },
    validateSelectedImageIds () {
      this.$set(this.fieldStates, 'selectedImageIds', this.selectedImageIds.length ? 'success' : 'error')
    },
    validateOrderIdRule (orderId) {
      const regex = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/g
      return regex.test(orderId) ? undefined : this.$i18n.translate('frs.zoneMaps.creation.messages.orderIdBadlyFormatted')
    }
  },
  watch: {
    selectedImageIds (ids) {
      // NOTE only display error on manual validate/save because the store will set the ids from time to time, resulting in invalid state without user input, which should not be shown as an error
      if (ids.length) {
        this.validateSelectedImageIds()
      }
    },
    orderId: {
      immediate: true,
      handler () {
        if (!!this.orderId && !this.validateOrderIdRule(this.orderId)) {
          this.getMapCountForOrder()
        } else {
          this.resetMapCount()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.algorithm-parameters {
  border: 1px solid lightgray;
  padding: 0.5em;
}

.buttons {
  position: relative;
  display: flex;
  margin-top: 1em;

  .quit-button {
    margin-right: auto;
  }
}

.cultivationTableContainer {
  margin-top: 30px;
  position: relative;
}

.zone-map-creation {
  padding: 5px;
}
</style>
