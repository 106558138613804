<template lang="html">
  <div class="load-more">
    <FrsLoadingIndicator
      small
      :loading="scheduledDateRanges.some(x => x.to === dateRange.to && x.from === dateRange.from)"
    />
    <IxButton
      @click="$emit('click', dateRange)"
    >
      <IxRes :context="{from: $i18n.format(dateRange.from, 'date'), to: $i18n.format(dateRange.to, 'date')}">
        frs.zoneMaps.buttons.loadMoreImages
      </IxRes>
    </IxButton>
  </div>
</template>

<script>
import IxButton from '@components/IxButton'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    FrsLoadingIndicator,
    IxButton
  },
  props: {
    dateRange: {
      type: Object,
      required: true
    },
    scheduledDateRanges: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.load-more {
  position: relative;
  padding: 0.5em;
  display: flex;
  justify-content: center;
}
</style>
