<template lang="html">
  <FrsModuleRoot
    :module="module"
    :module-path="['fieldRecordSystem', 'zoneMaps']"
    :translation-namespaces="['fi', 'Common.SR_Common']"
    :setup="loadPrerequisiteData"
  >
    <ZoneMapCreation />

    <template #left>
      <SatelliteImageSelection />
    </template>
  </FrsModuleRoot>
</template>

<script>
import {mapActions} from 'vuex'

import FrsModuleRoot from '@frs/components/base/FrsModuleRoot'

import ZoneMapCreation from './ZoneMapCreation'
import SatelliteImageSelection from './SatelliteImageSelection'

export default {
  components: {
    FrsModuleRoot,
    ZoneMapCreation,
    SatelliteImageSelection
  },
  computed: {
    module () {
      return () => import('@store/modules/field-record-system/zone-maps')
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/zoneMaps', [
      'loadPrerequisiteData'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
